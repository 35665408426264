<template>
  <div>
    <p class="text-2xl mb-6">Zetas</p>
    <ReporteZetas :filtrosShow="true" :exportar="['EXCEL']"></ReporteZetas>
  </div>


</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'

// demos
import ReporteZetas from './componentes/ReporteZetas.vue';
export default {
  components: {
    ReporteZetas
  },
  setup() {


    return {}
  },
}
</script>
