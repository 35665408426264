<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col cols="12" v-if="filtrosShow == true">
          <v-row>
            <v-col>
              <dos-fechas
                :fechas="[
                  FuncionesGenerales.formatoFecha(filtros.fecha.desde, 4),
                  FuncionesGenerales.formatoFecha(filtros.fecha.hasta, 4),
                ]"
                @getFechas="getFechas"
              ></dos-fechas>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="text-right" v-if="filtrosShow == true">
          <v-btn @click="cargarDatos(filtros)" x-small color="primary">Buscar</v-btn>
        </v-col>

        <v-col cols="12">
          <tabla-simple-reporte
            :height="400"
            :nombreReporte="
              'zetas ' +
              ' ' +
              FuncionesGenerales.formatoFecha(filtros.fecha.desde, 4) +
              ' al ' +
              FuncionesGenerales.formatoFecha(filtros.fecha.hasta, 4)
            "
            :cabeceraIn="cabecera"
            :datosIn="datos"
            :exportar="exportar"
          >
          </tabla-simple-reporte>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
  
<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import ComandaService from '@/api/servicios/ComandaService.js'
import TablaSimpleReporte from '@/components/TablaSimpleReporte.vue'
import FuncionesGenerales from '@/funciones/funciones'
import DosFechas from '@/components/DosFechas.vue'
import SelectGrupoNegocio from '@/components/SelectGrupoNegocio.vue'
// demos

export default {
  components: { TablaSimpleReporte, DosFechas, SelectGrupoNegocio },
  props: {
    filtrosShow: Boolean,
    exportar: Array,
  },
  setup() {
    const cabeceraInit = [
      { text: 'Fecha Sistema', value: 'fecha_sistema' },
      { text: 'Serial Impresora', value: 'serial_impresora' },
      { text: 'Numero z', value: 'numero_reporte_z' },
      { text: 'Tasa min', value: 'tasa_min' },
      { text: 'Tasa max', value: 'tasa_max' },
      { text: 'Primera Factura', value: 'numero_primera_factura_emitida' },
      { text: 'Ultima Factura', value: 'numero_ultima_factura_emitida' },
      { text: 'Primera nota de credito', value: 'numero_primera_nota_de_credito' },
      { text: 'Ultima nota de credito', value: 'numero_ultima_nota_de_credito' },
      { text: 'Exento Venta', value: 'exento_venta' },
      { text: 'BI General Venta', value: 'bi_general_venta' },
      { text: 'IVA General Venta', value: 'iva_general_venta' },
      { text: 'BI Reducido Venta', value: 'bi_reducido_venta', visible: false },
      { text: 'IVA Reducido Venta', value: 'iva_reducido_venta', visible: false },
      { text: 'BI Adicional Venta', value: 'bi_adicional_venta', visible: false },
      { text: 'IVA Adicional Venta', value: 'iva_adicional_venta', visible: false },
      { text: 'BI General NC', value: 'bi_general_nota_de_credito' },
      { text: 'IVA General NC', value: 'iva_general_nota_de_credito' },
      { text: 'BI Reducido NC', value: 'bi_reducido_nota_de_credito', visible: false },
      { text: 'IVA Reducido NC', value: 'iva_reducido_nota_de_credito', visible: false },
      { text: 'BI Adicional NC', value: 'bi_adicional_nota_de_credito', visible: false },
      { text: 'IVA Adicional NC', value: 'iva_adicional_nota_de_credito', visible: false },
      { text: 'BI IGTF', value: 'bi_igtf' },
      { text: 'Valor IGTF', value: 'valor_igtf' },
      { text: 'BI IGTF NC', value: 'bi_igtf_nota_de_credito' },
      { text: 'Valor IGTF NC', value: 'valor_igtf_nota_de_credito' },
      { text: 'Total General', value: 'total_general' },
    ]
    const cabecera = ref(JSON.parse(JSON.stringify(cabeceraInit)))
    const datos = ref([])

    const grupoNegocio = ref({})
    const filtros = ref({
      fecha: {
        desde: new Date(),
        hasta: new Date(),
      },
    })

    const cargarDatos = param => {
      filtros.value = param
      ComandaService.rptZetas(param)
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            if (response.data.datos.datos && response.data.datos.datos.length > 0) {
              cabecera.value = JSON.parse(JSON.stringify(cabeceraInit))

              response.data.datos.columnas.split(',').forEach(el => {
                cabecera.value.push({ text: el, value: el })
              })
              
              datos.value = response.data.datos.datos
            } else {
              // no se econcontro resultado")
            }
          }
        })
        .catch(err => {
          console.error(err)
          store.commit('setAlert', {
            message: err,
            type: 'error',
            error: {
                        ...err, 
                        response: error?.response?.data
                    },
            funcion: 'cargarDatos',
          })
        })
        .finally(() => {})
    }

    const getFechas = fecha => {
      filtros.value.fecha.desde = fecha.desde
      filtros.value.fecha.hasta = fecha.hasta
    }

    const exportExcelExterno = () => {
      let dato = {
        nombreHoja: 'Arqueo caja',
        cabecera: [],
        rows: [],
        anchoColumanas: [{ width: 20 }, { width: 30 }, { width: 30 }],
      }

      cabecera.value.forEach(col => {
        dato.cabecera.push(col.text)
      })

      let row = []
      datos.value.forEach(ro => {
        row = []
        cabecera.value.forEach((col, i) => {
          if (i >= 1) {
            row.push({
              v: ro[col.value],
              t: 'n',
              s: {
                numFmt: '0.00',
              },
            })
          } else {
            row.push({
              v: ro[col.value],
              t: 's',
            })
          }
        })

        dato.rows.push(row)
      })

      return dato
    }

    return {
      cabecera,
      datos,
      cargarDatos,
      filtros,
      getFechas,
      FuncionesGenerales,
    }
  },
}
</script>
  